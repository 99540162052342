import type { ContentTypeSiteConfigStoryblok } from '@/types/storyblok'
import type { ToRefs } from 'vue'

export const useConfigStore = defineStore('config', () => {
  const { fetchAsyncStory } = useStoryblokHelpers()
  const siteConfigBlok: Ref<ContentTypeSiteConfigStoryblok | undefined> = ref()

  async function fetchConfig(): Promise<Ref<ContentTypeSiteConfigStoryblok>> {
    if (siteConfigBlok.value) {
      return siteConfigBlok as Ref<ContentTypeSiteConfigStoryblok>
    }

    try {
      const res = await fetchAsyncStory<ContentTypeSiteConfigStoryblok>(
        '/config',
        { resolve_links: 'url' },
      )
      siteConfigBlok.value = res.value.content
      return siteConfigBlok as Ref<ContentTypeSiteConfigStoryblok>
    } catch (error) {
      console.error('Error fetching config:', error)
      throw createError({
        statusCode: 500,
        statusMessage: 'Error fetching config',
      })
    }
  }

  /**
   * Fetches the site config and returns it unwrapped as refs.
   * @returns {Promise<ToRefs<ContentTypeSiteConfigStoryblok>>}
   */
  async function asyncConfig(): Promise<
    ToRefs<ContentTypeSiteConfigStoryblok>
  > {
    await fetchConfig()
    return toRefs(siteConfigBlok.value as ContentTypeSiteConfigStoryblok)
  }

  return {
    fetchConfig,
    asyncConfig,
    siteConfigBlok,
  }
})
